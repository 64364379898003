<template>
  <el-container direction="vertical">
    <Header></Header>
    <el-main>
      <!--内容-->
      <div class="content">
        <div class="release_content">
          <el-form :model="release_from" label-position="left">
            <el-collapse v-model="select_name">
              <!--球员图片-->
              <!--封面信息 CoverInfo-->
              <el-collapse-item name="1">
                <template #title>
                  <div class="title">封面信息 Cover Info</div>
                </template>
                <el-form-item label="封面图片 Cover Image" label-width="300px">
                  <el-upload class="avatar-uploader" :on-change="before_cover_image_upload" accept="image/jpg,image/jpeg,image/png" :show-file-list="false" :auto-upload="false" >
                    <img alt="" v-if="release_from.cover_image" :src="cover_image_data" class="avatar" />
                    <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                  </el-upload>
                </el-form-item>
                <el-form-item label="封面标题 Cover Title" label-width="300px">
                  <el-input type="text" v-model="release_from.cover_title" placeholder="请输入封面标题" />
                </el-form-item>
                <el-form-item label="封面描述 Cover Describe" label-width="300px">
                  <el-input v-model="release_from.cover_describe" :rows="5" type="textarea" placeholder="请输入封面描述" />
                </el-form-item>
              </el-collapse-item>
              <el-input v-model="release_from.title" placeholder="请输入文章标题" class="input-with-select" size="large">
                <template #prepend>
                  <el-select v-model="release_from.type" placeholder="请选择类型" style="width: 115px">
                    <el-option label="球队" value="0" />
                    <el-option label="最佳" value="1" />
                    <el-option label="数据" value="2" />
                    <el-option label="皮肤" value="3" />
                    <el-option label="战术" value="4" />
                    <el-option label="视频" value="5" />
                    <el-option label="文章" value="6" />
                  </el-select>
                </template>
              </el-input>
              <br />
              <br />
              <div class="tinymce-editor">
                <Editor id="tinymceEditor" v-model="release_from.content" :init="editor_init" />
              </div>
              <br />
              <br />
              <div v-if="parseInt(this.release_from.type) >= 2 && parseInt(this.release_from.type) <=5">
                  <el-form-item label="下载信息" label-width="100px">
                    <el-input v-model="release_from.download.info" :rows="5" type="textarea" placeholder="请输入下载信息" />
                  </el-form-item>
                  <el-form-item label="所需积分" label-width="100px">
                    <el-input type="number" min="0" v-model="release_from.download.integral" placeholder="请输入下载所需积分" />
                  </el-form-item>
              </div>
              <br />
              <br />
              <el-form-item label="" label-width="400px">
                <el-button size="large" type="primary" @click="post_article_check">提交</el-button>
                <el-button size="large" type="primary">返回首页</el-button>
              </el-form-item>
            </el-collapse>
          </el-form>
        </div>
      </div>
    </el-main>
    <Footer></Footer>
  </el-container>
</template>

<script>
import tinymce from "tinymce/tinymce";
import Editor from "@tinymce/tinymce-vue";
import "tinymce/icons/default/icons";
import "tinymce/themes/silver";
import "tinymce/themes/silver/theme.min";
import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/plugins/image";
import "tinymce/plugins/link";
import "tinymce/plugins/autolink";
import "tinymce/plugins/code";
import "tinymce/plugins/table";
import "tinymce/plugins/lists";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/media";
import "tinymce/plugins/template";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/paste";
import "tinymce/plugins/preview";
import "tinymce/plugins/contextmenu";
import "tinymce/plugins/textcolor";
import "tinymce/plugins/emoticons";
import "tinymce/plugins/insertdatetime";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Plus } from '@element-plus/icons-vue';
import {error_hint, success_hint, warning_hint, jump_page} from "../../tool/tools";
import {image_upload, add_article} from "../../api/api";
import API_CONFIG from "../../../vue.config";

export default {
  name: "Release",
  data(){
    return {
      editor_init: {
        language_url: "/tinymce/langs/zh_CN.js",
        language: "zh_CN",
        skin_url: "/tinymce/skins/ui/oxide",
        content_css: "/tinymce/skins/content/default/content.css",
        width: "100%",
        height: 700,
        content_style: "* { padding:0; margin:0; } img {max-width:100% !important }",
        plugin_preview_width: 375, // 预览宽度
        plugin_preview_height: 668,
        lineheight_val: "1 1.1 1.2 1.3 1.35 1.4 1.5 1.55 1.6 1.75 1.8 1.9 1.95 2 2.1 2.2 2.3 2.4 2.5 2.6 2.7 2.8 3 3.1 3.2 3.3 3.4 4 5",
        fontsize_formats: "8pt 10pt 11pt 12pt 13pt 14pt 15pt 16pt 17pt 18pt 24pt 36pt",
        font_formats:"微软雅黑='微软雅黑'",
        plugins: "table lists link image insertdatetime media emoticons preview",
        powerpaste_word_import: "merge",
        toolbar: "fontselect | fontsizeselect | forecolor backcolor | bold italic underline  | blockquote | outdent indent alignleft aligncenter alignright alignjustify | bullist numlist | link |image insertdatetime media emoticons preview",
        emoticons_database_url: "/tinymce/plugins/emojis.min.js",
        paste_data_images: true,
        statusbar: false,
        menubar: true,
        images_upload_handler: (blobInfo, success, failure) => {
          image_upload({
            image: blobInfo.blob()
          }).then(result => {
            if(result.Code === 1){
              let image_url = API_CONFIG.devServer.proxy["/api"].target + "/" + result.Data.image_path;
              success(image_url);
            }else {
              failure("图片上传失败");
            }
          });
        }
      },
      select_name: "1",
      cover_image_data: null,
      release_from: {
        cover_image: "",
        cover_title: "",
        cover_describe: "",
        type: "",
        title: "",
        content: "",
        download: {
          info: "",
          integral: 0
        }
      },
    }
  },
  mounted() {
    tinymce.init({});
  },
  watch: {
  },
  methods: {
    jump_page,
    // 上传图片之前
    before_cover_image_upload(file){
      let file_size = file.size / 1024 / 1024 < 2;
      // 判断文件格式
      if(file.name.indexOf(".") === -1){
        error_hint("图片文件格式不对, 请重新选择文件");
        return false;
      }
      // 判断文件大小
      if(!file_size){
        error_hint("图片文件, 请控制在2MB以内");
        return false;
      }
      this.cover_image_data = URL.createObjectURL(file.raw);
      // 上传图片
      image_upload({
        image: file.raw
      }).then(result => {
        if(result.Code === 1){
          this.release_from.cover_image = result.Data.image_path;
          success_hint("图片上传成功");
        }else {
          error_hint("图片上传失败, 请重新选择图片上传");
        }
      });
    },
    post_article_check(){
      // 检查球员图片是否上传
      if(this.release_from.cover_image === "" || this.release_from.cover_image === null){
        warning_hint("请上传封面信息中的图片");
        return false;
      }
      if(this.release_from.cover_title === "" || this.release_from.cover_title === null){
        warning_hint("请输入封面信息中的标题");
        return false;
      }
      if(this.release_from.cover_describe === "" || this.release_from.cover_describe === null){
        warning_hint("请输入封面信息中的详情");
        return false;
      }
      if(this.release_from.title === "" || this.release_from.title === null){
        warning_hint("请输入标题");
        return false;
      }
      if(this.release_from.content === "" || this.release_from.content === null){
        warning_hint("请输入内容");
        return false;
      }
      if(this.release_from.type === "" || this.release_from.type === null){
        warning_hint("请选择类型");
        return false;
      }
      if(parseInt(this.release_from.type) >= 2 && parseInt(this.release_from.type) <= 4){
        if(this.release_from.download.info === "" || this.release_from.download.info === null){
          warning_hint("请输入下载信息");
          return false;
        }
      }
      // 上传数据
      this.post_article();
    },
    // 添加文章数据
    post_article(){
      // 类型转换
      add_article({
        cover_info: {
          cover_image: this.release_from.cover_image,
          cover_title: this.release_from.cover_title,
          cover_describe: this.release_from.cover_describe
        },
        title: this.release_from.title,
        content: this.release_from.content,
        type: this.release_from.type,
        download: {
          info: this.release_from.download.info,
          integral: parseInt(this.release_from.download.integral)
        }
      }).then(response => {
        if(response.Code === 1){
          success_hint("添加数据成功");
          this.jump_page("/");
        }
      });
    },
  },
  components: {
    Header,
    Footer,
    Plus,
    Editor
  }
}
</script>

<style scoped>
@import "../../static/universal/index.css";

.avatar-uploader .avatar {
  width: 200px;
  height: 200px;
  display: block;
}
.avatar-uploader .el-upload {
  border: 1px dashed black;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  border: 1px dashed black;
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 200px;
  text-align: center;
}

.content > .release_content {
  background: white;
  padding: 15px 20px;
}


.content > .release_content >>> .title{
  font-weight: bold;
  border-left: 3px solid #34b261;
  color: #34b261;
  padding-left: 9px;
  font-size: 16px;
}

</style>